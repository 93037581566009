<script>
    import adminProfileStructure from '@components/adminProfileStructure'
    import moment from 'moment'
    import UserCompany from "@components/modals/admin/UserCompany";
    import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
    import {mapState} from "vuex";
    import PaginationRow from "@components/global/PaginationRow";

    export default {
        page: {
            title: 'Admin',
        },
        mixins: [PaginatedDataMixin],
        components: {PaginationRow, UserCompany, adminProfileStructure},
        data() {
            return {
                modalUser: null,
              fields: [
                {key: 'isPremium', label: 'Συνδρομή;'},
                {key: 'email', label: 'E-mail'},
                {key: 'name', label: 'Ονοματεπώνυμο'},
                {key: 'subscriptionDue', label: 'Λήξη ετήσιας συνδρομής', formatter: value => {
                    return moment(value).format('DD/MM/YYYY')}
                },
                {key: 'createdAt', label: 'Ημερομηνία δημιουργίας', formatter: value => {
                    return moment(value).format('DD/MM/YYYY')}
                },
                {key: 'actions', label:'Ενέργειες'}
              ],
            }
        },
      computed:{
        ...mapState({
          user: state => state.auth.currentUser
        })
      },
        created: function(){
          this.api.url = "/admin/users";
          this.getData();
        },
        methods:{

            activateUserAndAddSubscription(user) {
                let message = {
                    title: 'Θέλετε σίγουρα να ενεργοποιήσετε τη συνδρομή;',
                    body: 'Θα προστεθεί υποχρέωση ανανέωσης της συνδρομής 1 έτος'
                };

                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Ενεργοποίηση',
                    cancelText: 'Ακύρωση',
                }).then((dialog) => {

                    this.$axios.post(`/admin/users/${user.id}/premium`).then(result => {
                        user.isPremium = true;
                        this.$notify({group: 'athlisis-notifications', duration: 5000, type:'success', title: this.$t('success.title'),
                            text: 'Ο χρήστης ενεγοποιήθηκε επιτυχώς!'});
                        dialog.close();
                    }).catch(e => {
                        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Κάτι πήγε λάθος!'});
                        dialog.close();
                    });
                });
            },
            extendUserSubscription(user){
              let message = {
                title: 'Θέλετε σίγουρα να επεκτείνετε τη συνδρομή;',
                body: 'Θα προστεθεί 1 έτος στη συνδρομή του χρήστη'
              };

              this.$dialog.confirm(message, {
                loader: true,
                okText: 'Επέκταση',
                cancelText: 'Ακύρωση',
              }).then((dialog) => {
                this.$axios.post(`/admin/users/${user.id}/extend-premium`).then(result => {
                  user.subscriptionDue = result.data.date;
                  this.$notify({group: 'athlisis-notifications', duration: 5000, type:'success', title: this.$t('success.title'),
                    text: 'Η συνδρομή του χρήστη επεκτάθηκε επιτυχώς!'});
                  dialog.close();
                }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Κάτι πήγε λάθος!'});
                  dialog.close();
                });
              });
            },
            showCompanyDetails(user){
                this.modalUser = user;
                this.$refs['show-company-details'].show();
            },

        }
    }
</script>
<template>
    <adminProfileStructure :user="user">
        <template v-slot:admin-structure-content>
          <b-card-title>
            Λίστα πελατών
          </b-card-title>

            <div class="row">
                <div class="col-md-6">
                    <b-form-input v-model="api.filters.search" type="search" class="form-control" placeholder="Αναζήτηση ..." debounce="400" />
                </div>
            </div>

            <div v-if="api.rows.length > 0" class="table-responsive table-borderless table-striped mb-0">
                <b-table :items="api.rows" :fields="fields" class="table table-hover" responsive="sm" :current-page="api.page">
                    <template v-slot:cell(img)="row" class="text-center">
                        <i :class="row.item.icon" class="text-center font-17"></i>
                    </template>
                  <template v-slot:cell(name)="row" class="text-center">
                    {{row.item.firstName + ' ' + row.item.lastName}}
                  </template>
                  <template v-slot:cell(isPremium)="row" class="text-center">
                    <b-badge v-if="row.item.isPremium" variant="success">✓</b-badge>
                    <b-badge v-else variant="danger">✗</b-badge>
                  </template>
                    <template v-slot:cell(actions)="row">
                      <b-dropdown variant="primary" size="xs" text="Ενέργειες" right>
                        <b-dropdown-item  v-if="!row.item.isPremium" href="#"  @click="activateUserAndAddSubscription(row.item)">Ενεργοποίηση συνδρομής</b-dropdown-item>
                        <b-dropdown-item  v-if="row.item.isPremium" href="#"  @click="extendUserSubscription(row.item)">Επέκταση ετήσιας συνδρομής (1 έτος)</b-dropdown-item>
                        <b-dropdown-item v-if="user.userCompany" href="#"  @click="showCompanyDetails(row.item)">Στοιχεία Τιμολόγησης</b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
            </div>

          <pagination-row :api="api" @fetchData="getData"></pagination-row>

            <b-modal id="show-company-details" ref="show-company-details" title="Στοιχεία τιμολόγησης" hide-footer>
              <UserCompany :user="modalUser" v-if="modalUser && modalUser.userCompany"></UserCompany>
            </b-modal>

        </template>
    </adminProfileStructure>
    <!-- end row-->
</template>
