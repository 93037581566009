<script>

export default{
  
  props:{
    user: Object
  }
}
</script>
<template>
  
  <div id="user-company">
    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-1" label="Επωνυμία εταιρείας" label-for="companyName" >
          <b-form-input id="companyName" v-model="user.userCompany.companyName" type="text" disabled></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-2" label="Επάγγελμα" label-for="occupation">
          <b-form-input id="occupation" v-model="user.userCompany.occupation" type="text" disabled></b-form-input>
        </b-form-group>
      </div>

      <div class="col-md-6">
        <b-form-group id="input-5" label="Σταθερό τηλέφωνο" label-for="telephone" >
          <b-form-input id="telephone" v-model="user.userCompany.telephone" disabled></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-6" label="Κινητό Τηλέφωνο" label-for="mobile">
          <b-form-input id="mobile" v-model="user.userCompany.mobile" disabled></b-form-input>
        </b-form-group>
      </div>

      <div class="col-md-12">
        <b-form-group id="input-3" label="Διεύθυνση" label-for="address" >
          <b-form-input id="address" v-model="user.userCompany.address" type="text" disabled></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-4" label="Α.Φ.Μ" label-for="VAT">
          <b-form-input id="VAT" v-model="user.userCompany.VAT" type="text" disabled></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-7" label="Δ.Ο.Υ." label-for="DOY" >
          <b-form-input id="DOY" v-model="user.userCompany.DOY" type="text" disabled></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
  
</template>